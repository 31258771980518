<template>
  <v-card min-height="194">
    <v-card-title>Receita</v-card-title>
    <div class="card-comissao">
      <div class="card-comissao-text">
        <v-card-subtitle>
          <p>R$ 483</p>
        </v-card-subtitle>
      </div>
      <div class="comissao-chart">
        <highcharts :options="chartOptions"></highcharts>
      </div>
    </div>
  </v-card>
</template>

<script>
import { Chart } from "highcharts-vue";

export default {
  name: "CardComissao",

  data() {
    return {
      chartOptions: {
        title: {
          text: ""
        },

        xAxis: {
          visible: false
        },

        yAxis: {
          visible: false
        },

        chart: {
          width: 170,
          height: 100
        },

        plotOptions: {
          column: {
            pointPadding: -0.1
          }
        },
        series: [
          {
            type: "column",
            color: "#CC152C",
            data: [
              29.9,
              71.5,
              106.4,
              129.2,
              144.0,
              176.0,
              135.6,
              148.5,
              216.4,
              194.1,
              95.6,
              54.4
            ],
            showInLegend: false
          }
        ],

        credits: {
          enabled: false
        }
      }
    };
  },

  components: {
    highcharts: Chart
  }
};
</script>

<style lang="scss">
.card-comissao {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.card-comissao p {
  color: rgba(0, 0, 0, 0.87);
  font-size: 1.5rem;
  font-weight: 700;
}

.comissao-chart {
  position: absolute;
  bottom: 0;
  right: 0;
}
</style>
